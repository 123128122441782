import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { getOrganizationalStructure } from "../api/apiOrganizationalStructure";

export function useOrganizationalStructure() {

    const {
        isLoading,
        data: chart,
        error,
    } = useQuery({
        queryKey: ["chart", i18next.language],
        queryFn: () => getOrganizationalStructure(),
        retry: false,
    });
    return { isLoading, error, chart };
}
