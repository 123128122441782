import React from 'react'
import Header from '../components/global/headers/Header'
import WrapperSection from '../components/global/WrapperSection'
import { Box, useMediaQuery } from '@mui/material'
import { useOrganizationalStructure } from '../hooks/useOrganizationalStructure'
import i18next from 'i18next'
import LoadingPage from '../components/global/LoadingPage'
import { useTheme } from '@emotion/react'

const OrganizationalStructure = () => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    const { isLoading,  chart } = useOrganizationalStructure()
    let lng = i18next.language
    if (isLoading) return <LoadingPage />
    const {data} = chart
    return (
        <>
            <Header page_name={data?.page_name} cover={data?.organization_page_cover} />
            <WrapperSection title={data?.main_title} description={data?.description} />
            <Box sx={{textAlign:'center'}}>
                <a href={data?.organization_ar_image} rel='noreferrer' target='_blank' >
                    <img
                        src={lng === 'ar' ? data?.organization_ar_image : data?.organization_en_image}
                        style={{ width: isMobile ? "100%" : "75%", height: isMobile ? 'auto':"450px", margin: '16px auto', objectFit:'contain' }}
                        alt="hekl" target='_blank' className="img-fluid"
                    />
                </a>
            </Box>
        </>
    )
}

export default OrganizationalStructure