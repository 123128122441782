import { Route, useLocation, Routes } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import LoadingPage from './components/global/LoadingPage';

import NavBar from './components/NavBars';
import Footer from './components/Footer/footers/Footer';
import ScrollToTopButton from './components/global/ScrollToTopButton';
import { useBasicData } from './hooks/useBasicData';
import { Helmet } from 'react-helmet-async';
import NotFound from './pages/NotFound';
// import Clients from './pages/Clients';
// import GreenInitiative from './pages/GreenInitiative';
import OrganizationalStructure from './pages/OrganizationalStructure';
// import Brands from './pages/Brands';

// Pages

const Home = lazy(() => import("./pages/Home"));
const AboutUs = lazy(() => import("./pages/About"));
const OurServices = lazy(() => import("./pages/OurServices"));
const Service = lazy(() => import("./pages/Service"));
const Projects = lazy(() => import("./pages/Projects"));
const PreviousWork = lazy(() => import("./pages/PreviousWork"));
// const Project = lazy(() => import("./pages/Project"));
// const OurBlogs = lazy(() => import("./pages/OurBlogs"));
// const Blog = lazy(() => import("./pages/Blog"));
const ContactUs = lazy(() => import("./pages/ContactUs"));

const RoutesPath = () => {
    const { basicData } = useBasicData()

    const ScrollToTop = () => {
        const { pathname } = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);
        return null;
    };

    return (
        <>
            <Helmet>
                <title>{basicData?.data?.name}</title>
                <link rel="icon" href={basicData?.data?.fav_icon} />
                <link rel="icon" href={basicData?.data?.fav_icon} />
                <meta name="description" content="Web site created using create-react-app" />
                <link rel="apple-touch-icon" href={basicData?.data?.fav_icon} />
                <link rel="manifest" href={basicData?.data?.fav_icon} />
            </Helmet>
            <NavBar />
            <ScrollToTop />
            <ScrollToTopButton />
            <Suspense fallback={<LoadingPage />}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/services" element={<OurServices />} />
                    <Route path="/service/:serviceSlug" element={<Service />} />
                    <Route path="/machinery-and-equipment" element={<Projects />} />
                    {/* <Route path="/project/:projectSlug" element={<Project />} /> */}
                    {/* <Route path="/blogs" element={<OurBlogs />} /> */}
                    {/* <Route path="/clients" element={<Clients />} /> */}
                    {/* <Route path="/green_initiative" element={<GreenInitiative />} /> */}
                    <Route path="/organizational-structure" element={<OrganizationalStructure />} />
                    <Route path="/previous-work" element={<PreviousWork />} />
                    {/* <Route path="/blog/:blogSlug" element={<Blog />} /> */}
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
            <Footer />
        </>
    );
};

export default RoutesPath;
